<!--
File: UnitsDropdown.vue
Description: show the dropdown combo with the units dropdown list .
-->
<template>
    <md-field>
      <label for="units">{{ label }}</label>
      <md-select v-model='id' name="units" id="units" :disabled="disabled" :required="is_required" @md-selected='onChange'>
        <md-option v-for='(unit, ind) in units' :key='ind' :value='unit.id'>
          {{ unit.description }}
        </md-option>
      </md-select>
    </md-field>
  </template>
  <script>
    export default {
      name: 'units-dropdown',
  
      props: {
        label: { default: null, type: String },
        initial_value: { default: null, type: Number },
        is_required: { default: false, type: Boolean },
        disabled: { default: false, type: Boolean }
      },
  
      data() {
        return {
          id: null,
          unitsListLoaded: false
        }
      },
  
      mounted() {
        this.$store.dispatch('LOAD_UNIT_LIST', !this.is_required).then(() => {
          this.unitsListLoaded = true
          if (this.initial_value && !this.id) this.id = this.initial_value
        })
      },
  
      methods: {
        onChange() {
          const desc = !this.id ? '' : this.units.find((element) => element.id === this.id).name
  
          this.$emit('input', this.id, desc)
        },
      },
  
      computed: {
        units() {
          return this.$store.state.Units.list;
        }
      },
  
      watch: {
        initial_value(newValue, oldValue) {
          if (newValue !== oldValue && this.unitsListLoaded) {
            this.key = newValue
          }
        },
      }
    }
  </script>